@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/mem5YaGs126MiZpBA-UN_r8-Vg.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/mem8YaGs126MiZpBA-U1UQ.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../assets/fonts/mem5YaGs126MiZpBA-UNirk-Vg.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/mem5YaGs126MiZpBA-UN7rg-Vg.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../assets/fonts/mem5YaGs126MiZpBA-UN8rs-Vg.woff) format('woff');
}
